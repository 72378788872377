import axios from "axios";
import {
  SessionStorageSubscription,
  SessionStorageToken,
} from "../../consts/Fuction";

export const shipperUrl = `https://api-stage.agrigator.co/shipper/api/`;
const baseURL = `https://api-stage.agrigator.co/engine/api/`;

//const baseURL = `http://admin-uat.agrigator.co:4000`;
export const mainInstance = axios.create({
  baseURL: baseURL,
});

const makeRequest =
  (instance) =>
    (method, url, ...params) => {
      const token = SessionStorageToken();
      const subscription = SessionStorageSubscription();
      if (token) {
        instance.defaults.headers.common["Authorization"] = `JWT ${token}`;
        instance.defaults.headers.common["PushNotification"] = subscription;
      }
      instance.defaults.headers.post["Content-Type"] = "application/json";
      instance.interceptors.request.use((config) => {
        return config;
      });
      instance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      return instance[method](url, ...params);
    };

const makeFormRequest = (instance) => (method, url, params) => {
  const token = SessionStorageToken();
  const subscription = SessionStorageSubscription();
  if (token) {
    instance.defaults.headers.common["Authorization"] = `JWT ${token}`;
    instance.defaults.headers.common["PushNotification"] = subscription;
    instance.defaults.headers.patch["Content-Type"] = "multipart/form-data";
  }
  instance.interceptors.request.use((config) => {
    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance[method](url, ...params);
};

const request =
  (method, url) =>
    (...params) =>
      makeRequest(mainInstance)(method, url, ...params);


const formDataRequest =
  (method, url) =>
    (...params) =>
      makeFormRequest(mainInstance)(method, url, params);

export const auth = {
  login: (...params) => request("post", "/user/login")(...params),
  verifyOtp: (...params) => request("post", "/user/verifyAdminOtp")(...params),
  updateUser: (...params) =>
    formDataRequest("put", "/user/updateUser")(...params),
  resendOtp: (...params) => request("post", "/user/resendOtp")(...params),
  logout: (...params) => request("post", "/user/logout")(...params),
};

export const admin = {
  dispatch: () => request("get", "/admin/getDispatch")(),
  dispatchWithPagination: (_query) =>
    request("get", `/admin/getDispatchWithPagination?${_query}`)(),
  updateStatus: (...params) => request("put", "/admin/updateStatus")(...params),

  updateVehicleDetail: (...param) =>
    request("patch", "/admin/updateVehicleDetail")(...param),
  updateInvoice: (...param) =>
    formDataRequest("patch", "/admin/updateInvoice")(...param),
  updateIntransitDetail: (...params) =>
    formDataRequest("patch", "/admin/updateIntransitDetail")(...params),

  createAdvancePayment: (...params) =>
    request("post", "/admin/advancePayment")(...params),
  createBalancePayment: (...params) =>
    request("post", "/admin/balancePayment")(...params),

  advancePaymentByCheque: (...params) =>
    formDataRequest("patch", "/admin/advanceCheque")(...params),
  balancePaymentByCheque: (...params) =>
    formDataRequest("patch", "/admin/balanceCheque")(...params),

  totalBusiness: () => request("get", "/admin/getTotalBusiness")(),
  advancePendingOrders: () =>
    request("get", "/admin/getAdvancePendingOrders")(),
  totalAcceptedOrders: () => request("get", "/admin/getTotalAcceptedOrders")(),
  totalPendingOrders: () => request("get", "/admin/getTotalPendingOrders")(),
  totalOrders: () => request("get", "/admin/getTotalOrders")(),

  nonVerifiedShipperCustomers: () =>
    request("get", "/admin/getnonVerifiedShipperCustomers")(),
  verifiedShipperCustomers: (_query) =>
    request("get", `/admin/getShipperList?${_query}`)(),

  createOrder: (...params) => request("post", "/admin/createOrder")(...params),
  getCustomerDetail: (id) =>
    request("get", `/admin/getCustomerDetail?id=${id}`)(),
  updateCustomer: (...params) =>
    request("put", "/admin/updateCustomerDetail")(...params),
  createCustomer: (...params) =>
    request("post", "/admin/createCustomer")(...params),

  updateGstImage: (...params) =>
    formDataRequest("put", "/admin/updateGstImage")(...params),
  updatePanImage: (...params) =>
    formDataRequest("put", "/admin/updatePanImage")(...params),
  updateAadharImage: (...params) =>
    formDataRequest("put", "/admin/updateAadharImage")(...params),
  updateAddressImage: (...params) =>
    formDataRequest("put", "/admin/updateAddressImage")(...params),
  updateVisitingCardImage: (...params) =>
    formDataRequest("put", "/admin/updateVisitingCardImage")(...params),
  updateMsmeImage: (...params) =>
    formDataRequest("put", "/admin/updateMsmeImage")(...params),
  updateCoiImage: (...params) =>
    formDataRequest("put", "/admin/updateCoiImage")(...params),
  updateCompanyImage: (...params) =>
    formDataRequest("put", "/admin/updateCompanyImage")(...params),
  updateMandiImage: (...params) =>
    formDataRequest("put", "/admin/updateMandiImage")(...params),
  updateOffice1Image: (...params) =>
    formDataRequest("put", "/admin/updateOffice1Image")(...params),
  updateOffice2Image: (...params) =>
    formDataRequest("put", "/admin/updateOffice2Image")(...params),
  updateOffice3Image: (...params) =>
    formDataRequest("put", "/admin/updateOffice3Image")(...params),
  updateOwnerImage: (...params) =>
    formDataRequest("put", "/admin/updateOwnerImage")(...params),
  viewDispatch: (id) => request("get", `/shipper/viewDispatch/${id}`)(),
  notificationsStatics: () => request("get", "/admin/notification/statics")(),
  dispatchCaluclation: (id) =>
    request("get", `/admin/getDispachCalculation?orderId=${id}`)(),
  transectionSheet: (_query) =>
    request("get", `/admin/getTransectionSheet?${_query}`)(),
};

export const notification = {
  subscriber: (...params) => request("post", "/notification")(...params),
  getNotifications: () => request("get", "/notification")(),
  updateNotification: (...params) =>
    request("put", "/notification/updateNotification")(...params),
};
export const shipper = {
  dispatch: (...params) => request("post", "/shipper/dispatch")(...params),
  activeDispatch: () => request("get", "/shipper/active")(),
  viewDispatch: (id) => request("get", `/shipper/viewDispatch/${id}`)(),
  paymentDispatch: () => request("get", "/shipper/payment")(),
  historyDispatch: () => request("get", "/shipper/history")(),
  getRecentDispatch: () => request("get", "/shipper/getRecentDispatch")(),

  makeAdvancePayment: (...params) =>
    request("post", "/shipper/makeAdvancePayment")(...params),
  makeBalancePayment: (...params) =>
    request("post", "/shipper/makeBalancePayment")(...params),

  getTotalBusiness: () => request("get", "/shipper/getTotalBusiness")(),
  getTotalOrders: () => request("get", "/shipper/getTotalOrders")(),
  getTotalActiveOrders: () => request("get", "/shipper/getTotalActiveOrders")(),
  getTotalPayment: () => request("get", "/shipper/getTotalPayment")(),
};
export { baseURL };
